// Simule le défilement infini sur mobile pour les rayons et la recherche.
// En jQuery pour éviter les bugs JS sur mobile avec la simulation du clic.
// Cette fonction est conditionnée selon la valeur de #is_loading dans le DOM afin d'éviter un nombre trop conséquent de clics pendant la requête. Voir => generateNewBlocProd.

// La fonction isInViewport sert à contrôler si le bouton apparaît à l'écran. Si c'est le cas, le clic est simulé, sinon non. Cette fonction est appelée dans la condition du clic lors du défilement.

function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

$(function () {
    function generateScrollInfini() {
        if($('body').hasClass('category') || $('body').hasClass('search')) {
            $(window).scroll(function () {
                var isLoading = $('#is_loading').val();
                const maxItems = document.getElementById('totalElems').value;
                const productLoaded = $('#scroll_items .item_container:not(".push")').length;
                const pagerNav = document.querySelector('.pagerNav.end_pagi');
                const button = document.querySelector('.see_all_product');

                // Si le premier clic a eu lieu, alors la variable passe à true et permet de valider la condition ci-dessous. On masque le bloc de pagination qui ne s'affichera que si tous les items sont chargés, et si isInViewport retourne true et que isLoading est repassé à 0 (voir => generateNewBlocProd). Alors, le clic est déclenché et la variable isLoading repasse à 1 pour bloquer tout nouveau clic avant la fin de la requête Ajax.
                button.style.opacity = '1';
                pagerNav.style.opacity = productLoaded < maxItems ? '0' : '1';

                if (isInViewport(button) && isLoading == 0) {
                    $('.button.see_all_product').trigger('click');
                    $('#is_loading').val('1');
                }
            });
        }  
    }

    generateScrollInfini();
});