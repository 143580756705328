/***** ACCOUNT *****/

/**
 * Swiper mobile "Mon compte" menu
 */
if (($('body').hasClass('customer') || $('body').hasClass('newsletter') || $('body').hasClass('wishlist') || $('body').hasClass('order')) && $('#menu_account_slider_mob').length > 0) {
    setTimeout(function () {

        $('.accountMenuWrapper').css({'display': 'flex'});

        var menuInitialSlide = 0;

        $('.menu_account_slider_mob .swiper-slide').each(function (i, obj) {
            if ($(obj).hasClass('activeMenu')) {
                menuInitialSlide = i;
            }
        });

        var menuAccSwiperMob = new Swiper('#menu_account_slider_mob', {
            slidesPerView: 2.5,
            centeredSlides: true,
            initialSlide: menuInitialSlide,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            slidesOffsetBefore: 30,
            slidesOffsetAfter: 30,
            onInit: function () {
                $('.menu_account_slider_mob').fadeTo("slow", 1);
            },
        });
    }, 1000);
}

if (getCookie("cookie_header_delivery") != "accepted" && $(".bandeauMenuAffix").length > 0) {
    $('body').scrollTop(0);
    setTimeout(function () {
        //declare var
        var geFreeShipping = $('.ge-free-shipping-container');
        var top_search = $('#top_search');
        var banner_nav = $('.banner_nav');
        var bandeauMenuAffix = $('.bandeauMenuAffix');
        var heightBannerTotal = geFreeShipping.height() + bandeauMenuAffix.height();

        var hasGbeBandeau = $('#GlobaleFreeShippingBannerContainer').html() != '';
        var geFreeShipping = $('.ge-free-shipping-container');
        if (!$("body").hasClass('product_page') && hasGbeBandeau) {
            $("body").css("padding-top", "7.5vmin");
            geFreeShipping.css("opacity", 1);
        }

        if (!$("body").hasClass('homepage') && !$("body").hasClass('product_page') && !$("body").hasClass('wishlist') && hasGbeBandeau) {
            $("#contents_wrapper").css("padding-top", "28.5vmin");
        }

        top_search.css("margin-top", heightBannerTotal - 29);
        banner_nav.css("top", heightBannerTotal - 29);

        $(".bandeauMenuAffix").animate({
            opacity: 1
        }, 150);


        $(window).scroll(function () {
            var height = $(window).scrollTop();
            if (height > 50 && !$("body").hasClass('product_page')) {
                banner_nav.css("top", 0);
                top_search.css("margin-top", 0);
                geFreeShipping.hide();
            } else {
                top_search.css("margin-top", heightBannerTotal - 29);
                banner_nav.css("top", heightBannerTotal - 29);
                geFreeShipping.fadeIn();
            }

            if ($("body").hasClass('product_page')) {
                var bottomVisuel = $('.slider_product_container ').position().top + $('.slider_product_container ').outerHeight(true);

                if (height > bottomVisuel) {
                    banner_nav.css("top", 0);
                    top_search.css("margin-top", 0);
                    geFreeShipping.hide();
                    $('.search_hidden .ge-free-shipping-container').css('opacity', 0);
                } else {
                    top_search.css("margin-top", heightBannerTotal - 29);
                    banner_nav.css("top", heightBannerTotal - 29);
                    geFreeShipping.show();
                    $('.search_hidden .ge-free-shipping-container').css('opacity', 1);
                }
            }
        });

    }, 1000);

}


/**
 * Page messages - gestion de la trad d'intro
 */
if ($('.site_contents_wrapper .mes_messages').length) {
    $('.site_contents_wrapper .mes_messages h1').after('<div class="messages_intro">' + Translator.translate('intro_mes_messages') + '</div>');
}

function searchToggle() {

    var top_search = $('#top_search'),
        main_menu_btn = $('#main_menu_btn'),
        search_home = $('#search_home'),
        shade = $('#shade'),
        body = $('body');

    if (top_search.hasClass('opened')) {

        if (main_menu_btn.hasClass('menu_open')) {

            main_menu_btn.click();
            search_home.focus();
        } else {

            top_search.removeClass('opened');
            body.removeClass('search_opened');
            shade.removeClass('visible');
        }
    } else {

        if (main_menu_btn.hasClass('menu_open')) {

            main_menu_btn.click();
            top_search.addClass('opened');
            body.addClass('search_opened');
            search_home.focus();
            shade.addClass('visible');

            shade.on('click', function () {

                if (!search_home.val()) {

                    searchToggle();
                }
            });
        } else {

            top_search.addClass('opened');
            body.addClass('search_opened');
            search_home.focus();
            shade.addClass('visible');

            shade.on('click', function () {

                if (!search_home.val()) {

                    searchToggle();
                }
            });
        }
    }
}

function clearSearch() {

    var search_home = $('#search_home');

    if (!search_home.val()) {

        searchToggle();
    } else {

        search_home.val('');
        search_home.focus();
    }
}

$(function () {

    if ($('.customer #adresseForm').length) {
        $('.customer #adresseForm #btn_addr_add span').text(Translator.translate('enter_new_adr_mobile'));
        $('.customer #adresseForm #btn_addr_add span').css('opacity', '1');
    }

    if ($('.bandeauMenuAffix').length) {
        $('body').addClass('hasBando');
    }

    // Footer accordion
    $('.footer_accordion_ctn .footer_link_title').each(function () {
        $(this).click(function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).parent().find('.footer_link_content').removeClass('active').slideUp(300);
            } else {
                $('.footer_accordion_ctn .footer_link_title').removeClass('active');
                $('.footer_accordion_ctn .footer_link_content').removeClass('active').slideUp(300);
                $(this).addClass('active');
                $(this).parent().find('.footer_link_content').addClass('active').slideDown(300);
            }
        });
    });

    setTimeout(function () {

        if ($('#sliderBando').length && $('#sliderBando .swiper-slide').length > 1) {
            var sliderBando = new Swiper('#sliderBando', {
                slidesPerView: 1,
                loop: true,
                autoplay: 3000,
                speed: 1000
            });
        }

    }, 1000);

    /***** HOMEPAGE *****/

    /* Cover module swiper */
    if ($('#super_home_swiper.swiper-container').length) {
        new Swiper('#super_home_swiper.swiper-container', {
            loop: true,
            slidesPerView: 1,
            speed: 2000,
            autoplay: 5000,
            pagination: '.swiper-pagination',
        });
    }

    /**
     * Account dropdown from menu
     */
    if ($('#left_menu .bloc_lnk.user.connected.dropdown').length > 0) {

        $(document).on('click tap touch', '.bloc_lnk.user.connected.dropdown', function () {

            if ($(this).hasClass('active')) {

                $(this).removeClass('active');
                $(this).parent().find('.dropdown.mon-compte').slideUp(300);
            } else {

                $(this).addClass('active');
                $(this).parent().find('.dropdown.mon-compte').slideDown(300);
            }
        });
    }

    /*setTimeout(function(){
        $('.switchLangWrapper').on('click tap touch', function(){
            $(this).toggleClass('displayed');
        })
    },800);*/

    var socialModuleSwiper1 = new Swiper('.socialModuleSwiper1', {
        direction: 'vertical',
        slidesPerView: 2,
        spaceBetween: 15,
        loop: true,
        speed: 6000,
        autoplay: {
            delay: 10,
        },
    });

    var socialModuleSwiper2 = new Swiper('.socialModuleSwiper2', {
        direction: 'vertical',
        slidesPerView: 2,
        spaceBetween: 15,
        loop: true,
        speed: 6000,
        autoplay: {
            delay: 10,
            reverseDirection: true
        },
    });

    if ($('.product_page #carousel_wrapper .swiper-slide').length > 2) {
        var productVisitedSwiper = new Swiper('#carousel_wrapper .assos_rayon.swiper-container ', {
            slidesPerView: 2,
            spaceBetween: 15,
            loop: false,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            }
        });

        if ($('#carousel_wrapper .swiper-slide').length > 4) {
            document.querySelector('.assos_rayon .swiper-pagination').style.display = "block";
        }
    }

    if ($('.product_page #gondole_derniers_articles_vus .swiper-slide').length > 2) {
        var productVisitedSwiper = new Swiper('#gondole_derniers_articles_vus .visited_pdt_holder.swiper-container ', {
            slidesPerView: 2,
            spaceBetween: 15,
            loop: false,
        });
    }

    //Cas ou pas de "vous aimerez aussi"
    if ($('.product_page #carousel_wrapper .swiper-slide').length < 1) {
        $("#gondole_derniers_articles_vus").css('height', 'auto');
    }

    if ($(".wrap_product_bottom").length) {

        var triggerAimerez = $(".switch_assos_fp .toggle_assos_fp");
        var triggerVus = $(".switch_assos_fp .toggle_vus_recemment");
        var godoleAimerez = $("#carousel_wrapper");
        var godoleVus = $("#gondole_derniers_articles_vus");

        $(triggerAimerez).on('click tap touch', function () {
            if (!$(triggerAimerez).hasClass('underlined')) {
                $(triggerAimerez).addClass('underlined');
                $(godoleAimerez).show();
                $(triggerVus).removeClass('underlined');
                $(godoleVus).hide();
            }
        });

        $(triggerVus).on('click tap touch', function () {
            if (!$(triggerVus).hasClass('underlined')) {
                $(triggerVus).addClass('underlined');
                $(godoleVus).show();
                $(godoleVus).css('height', 'auto');
                $(triggerAimerez).removeClass('underlined');
                $(godoleAimerez).hide();
            }
        });
    }

    /* FICHE PRODUIT - REASSURANCE SWIPER */
    if ($(".product_page .rollover_reassurance_swiper").length) {
        reassuSwiper = new Swiper(".rollover_reassurance_swiper", {
            loop: true,
            slidesPerView: 'auto',
            centeredSlides: true,
            speed: 5000,
            spaceBetween: 15,
            autoplay: {
                delay: 1,
            },
            preventInteractionOnTransition: true,
            disableOnInteraction: false
        });
    }

    /* TUNNEL - REASSURANCE SWIPER */
    if ($("#reassuranceSwiperTunnel").length) {
        reassuSwiper = new Swiper("#reassuranceSwiperTunnel", {
            loop: true,
            slidesPerView: 'auto',
            centeredSlides: true,
            speed: 5000,
            spaceBetween: 15,
            autoplay: {
                delay: 1,
            },
            preventInteractionOnTransition: true,
            disableOnInteraction: false
        });
    }

    /**
     * Toggle txt SEO - PRODUCT PAGE
     */
    if ($('.product_page .description .see_more_txt').length) {

        $('.description .see_more_txt').on('click tap touch', function () {
            var heightToToggle = $(this).siblings('.txt_content').height();

            if ($(this).parent('.description').hasClass('actif')) {

                $(this).parent('.description').removeClass('actif');
                $(this).parent('.description').animate({height: "90px"}, 500);
                $(this).children('.see_more_txt_cta').text(Translator.translate('txt_show_more'));

            } else {

                $(this).parent('.description').addClass('actif');
                $(this).parent('.description').animate({height: heightToToggle + 50}, 500);
                $(this).children('.see_more_txt_cta').text(Translator.translate('txt_show_less'));
            }
        });
    }

    /**
     * Toggle txt SEO - CATEGORY PAGE - FOOTER
     */
    if ($('.category #footer_seo_content .see_more_txt').length) {

        $('#footer_seo_content .see_more_txt').on('click tap touch', function () {
            var heightToToggle = $(this).siblings('.txt_content').height();

            if ($(this).parent('#footer_seo_content').hasClass('actif')) {

                $(this).parent('#footer_seo_content').removeClass('actif');
                $(this).parent('#footer_seo_content').animate({height: "90px"}, 500);
                $(this).children('.see_more_txt_cta').text(Translator.translate('txt_show_more'));

            } else {

                $(this).parent('#footer_seo_content').addClass('actif');
                $(this).parent('#footer_seo_content').animate({height: heightToToggle + 40}, 500);
                $(this).children('.see_more_txt_cta').text(Translator.translate('txt_show_less'));
            }
        });
    }

    /**
     * Toggle txt SEO - CATEGORY PAGE - HEADER
     */
    if ($('.category #content_bandeau .see_more_txt').length) {

        $('#content_bandeau .see_more_txt').on('click tap touch', function () {
            var heightToToggle = $(this).siblings('.txt_content').height();

            if ($(this).parent('#content_bandeau').hasClass('actif')) {

                $(this).parent('#content_bandeau').removeClass('actif');
                $(this).parent('#content_bandeau').animate({height: "90px"}, 500);
                $(this).children('.see_more_txt_cta').text(Translator.translate('txt_show_more'));

            } else {

                $(this).parent('#content_bandeau').addClass('actif');
                $(this).parent('#content_bandeau').animate({height: heightToToggle + 40}, 500);
                $(this).children('.see_more_txt_cta').text(Translator.translate('txt_show_less'));
            }
        });
    }

    /* FP - REPLACE WISHLIST BUTTON TO AVOID OVERRIDE */
    if ($('.product_page .prod_wishlist_btn').length) {
        $('.addwishlist_container').append($('.product_page .prod_wishlist_btn'));
        $('.addwishlist_container').removeClass('fakeWish');
        $('.prod_wishlist_btn').css('opacity', 1);
        $('#add_basket_wrapper').after($('.addwishlist_container'));
    }

    /* LOOKBOOK */

    /* Text swiper */
    if ($('#lookTextSwiper0').length) {
        var lookSwiper = new Swiper('#lookTextSwiper0', {
            loop: true,
            slidesPerView: 1,
            speed: 5000,
            autoplay: {
                delay: 1,
            },
        });
    }

    /* Pics swiper(s) */
    if ($('#lookPicsSwiper4').length) {
        var lookSwiper = new Swiper('#lookPicsSwiper4', {
            slidesPerView: 2.25,
            spaceBetween: 16
        });
    }

    if ($('#lookPicsSwiper8').length) {
        var lookSwiper = new Swiper('#lookPicsSwiper8', {
            slidesPerView: 2.25,
            spaceBetween: 16
        });
    }

    if ($('#btn_pwd').length) {
        $('#btn_pwd span').text(Translator.translate('save_password'));
    }

    setTimeout(function () {
        /* page customer - add class if there is shipping bando */
        if ($('body.customer').hasClass('en') && $('.ge-free-shipping-container-mobile').length) {
            $('.accountMenuWrapper').addClass('hasFreeShippingBando');
        }
    }, 2000);

    // hide price order GBE & replace link returns in footer
    setTimeout(function () {
        checkImgFlagGe();
    }, 50);

    setTimeout(function () {
        if (!$('.ge-free-shipping-container-mobile').length && $('body').hasClass('fr')) {
            $('#appContainer .montant_cmd').css('opacity', 1);
            $('#appContainer .sub_tunnel .order_recap').show();
            $('#appContainer .sub_tunnel .cart_item_container .price_tag').show();
        }
    }, 1500);

    setTimeout(function () {
        $('#wrap_form').overlayScrollbars({});
    }, 500);

    $('.js-content li p').on('click', function () {
        $(this).toggleClass('active');
        $(this).next().toggleClass('active');
    });
});

/**
 * Ajout le fonction faqinit()
 * sur mobile
 */
function faqinit(array_questions) {

    $(document).on('change keyup focus', '#faq_search', function () {

        $('#theme_page .theme').each(function () {
            $(this).removeClass('active');
        });

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {

            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
            }

        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function () {
        if (!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    $('#theme_global .theme').on('click', function () {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display', 'none');
        theme.addClass('actif');
        showTabFaq(id, 1)
    });

    $('#theme_page .theme').on('click', function () {
        var id = $(this).data('id');
        showTabFaq(id, 1);

        if (!$(this).hasClass('active')) {
            $('#theme_page .theme').each(function () {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }

    });
}

function researchFaq(node_id, level, question_id) {

    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({scrollTop: $(".faq_question_" + question_id + "").offset().top - 100}, 1000);

    $('#theme_page .theme[data-id=' + node_id + ']').addClass('active');

}

// TUNNEL REASSURANCE SWIPER PARAMS
var oReassuSwiper = {
    'element': '#reassuranceSwiperTunnel',
    'params': {
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        speed: 5000,
        spaceBetween: 15,
        autoplay: {
            delay: 1,
        },
        preventInteractionOnTransition: true,
        disableOnInteraction: false
    },
};

// Chargement des différentes tarification
$.ajax({
    url: path_relative_root + 'ajax_tarification_list.php',
    type: 'post',
    dataType: 'json',
    success: function (res) {
        if (res.length > 0) {
            var div = $('<div>', {'class': 'custom-select custom-select-footer'});
            var select = $('<select id="country_tarif" name="country">');
            $.each(res, function (key, val) {
                var option = $("<option />", {value: key, text: val.pays_nom});
                if (val.selected) {
                    option.prop('selected', true)
                }
                $(option).appendTo(select);
            });
            // Event sur le select lorsque l'on change de pays
            select.on('change', function () {
                var country_iso = $(this).val();
                // On va envoyé au serveur que l'on change de pays
                $.ajax({
                    url: path_relative_root + 'ajax_tarification_change_country.php',
                    type: 'post',
                    data: 'iso=' + country_iso,
                    success: function () {
                        location.reload();
                    }
                });
            });
            select.appendTo(div);
            div.appendTo($("#tarification_load"));
            if (typeof initCustomSelect === "function") {
                initCustomSelect('custom-select-footer');
            }
        } else {
            $("#tarification_load").css('display', 'none');
        }
    }
});


function dataLayer_associated_product() {

    if (window.$('.assoc_carousel_wrapper')) {

        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        var productObj = null;

        if (product_id !== '') {
            if (typeof data_layer_products != 'undefined' && data_layer_products.length > 0) {
                data_layer_products.forEach(function (item) {
                    if (item.id == product_id) {
                        productObj = item;
                    }
                })
            } else if (typeof data_layer_products_aimerez != 'undefined') {
                var objs = Object.keys(data_layer_products_aimerez);
                var values = Object.values(data_layer_products_aimerez);

                for (var i = 0; i < objs.length; i++) {
                    if (values[i].id == product_id) {
                        productObj = values[i];
                    }
                }
            }
            var prod = productObj;

            if (productObj !== null && productObj !== undefined) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }
}

/* UPSELLING ALMA PAGE panier GlobalE */
function hideUpsellingAlma() {
    var upselling_alma_livraison = $('.cart .upselling_alma');
    if (getCookie("GlobalECartId") != '') {
        upselling_alma_livraison.hide();
    }
}

setTimeout(function () {
    hideUpsellingAlma();
}, 4000);

/* CHECK COOKIE GLOBAL E */
function checkCookieGlobalE() {
    if (getCookie("GlobalECartId") != '') {
        if (typeof GEM_Services != "undefined" && GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings != undefined) {
            var isOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
            if (isOperatedByGlobalE) {
                var linkReturnsFooter = $('.footer_accordion_ctn .list_links:first-child .footer_link_content a:nth-child(5)');
                linkReturnsFooter.attr('href', gbe_traking_link);

            }
        }
    }

}

setTimeout(function () {
    checkCookieGlobalE();
}, 50);

/* REPLACE URL FOOTER & hide montant in order & customer page  */
function checkImgFlagGe() {
    if (!$('body').hasClass('fr') && $('#GlobaleFreeShippingBannerContainer').length) {
        var linkReturnsFooter = $('.footer_accordion_ctn .list_links:first-child .footer_link_content a:nth-child(5)');
        linkReturnsFooter.attr('href', gbe_traking_link);
        //detail order
        if ($('body.customer').length) {
            $('body.customer .montant_cmd').css('opacity', 0);
        }
        if ($('body.order').length) {
            $('body.order .sub_tunnel .order_recap').hide();
            $('body.order .sub_tunnel .cart_item_container .price_tag').hide();
        }
    }
}

// Affichage du bloc qui gére le numero de retour
function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {
    if (typeof document.getElementById("popup_numero_retour") != "undefined") {
        var numDIVObjet = document.getElementById("popup_numero_retour");

        numDIVObjet.style.display = "block";

        document.getElementById("general_shade").style.display = "block";
    }
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {

    var formId = '#return_products_form';
    formId += '_' + (multiTransp ? idOrderTransp : idOrder);

    var has_errors = false;

    $(formId + " .return_quantity").each(function () {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $('#return_products_form_' + idOrder).serialize();
        data += '&idOrder=' + (multiTransp ? idOrderTransp : idOrder) + '&multiTransp=' + multiTransp;

        $.ajax({
            url: path_relative_root + 'ajax_create_etiquette.php',
            type: 'post',
            data: data,
            success: function (res) {
                location.reload();
            }
        });
    }
}

// Commande mobile end


function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp) {
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    var data = 'idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;
    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize() + '&' + data;
    }

    $.ajax({
        url: path_relative_root + 'ajax_create_etiquette.php',
        type: 'post',
        data: data,
        success: function (res) {
            if (res.substring(0, 5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

function closeMultiShad(id) {

    $('#shade').off('click');

    // Hide modbox and shade and destroy shade's click event
    $('#' + id).slideUp("slow").queue(function () {

        if ($('#shade').is(":visible")) {
            $('#shade').fadeTo("slow", 0, function () {

                $('#shade').css('display', 'none');
            });
        }

        $(this).dequeue();
    });
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    page = page || 1;
    var idObj = getCurrentIdRayon();
    var bloc_page = page - 1;

    /**
     * Mise à jour des valeurs de pages d'origine pour le scroll infini
     */
    if ($("#scroll_origin").length) {
        if (!fromScrollBottom && fromScrollTop) {
            $("#scroll_origin").val(page);
        } else if (fromScrollBottom && !fromScrollTop) {
            $("#initial_page").val(page);
        } else {
            $("#scroll_origin, #initial_page").val(page);
        }
    }

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    if (from_type === "product") {
        generateNewBlocProd(
            bloc_page,
            null,
            idObj,
            null,
            null,
            fromScrollBottom,
            fromScrollTop,
            true
        );
    } else {
        generateNewBlocSearch(
            document.getElementById("nb_prod").value,
            bloc_page,
            null,
            fromScrollBottom,
            fromScrollTop
        );
    }
}

function paginate(page) {
    var form = document.getElementById("filters_form"),
        total_element = parseFloat($("#totalElems").val()),
        nb_prod = parseFloat($("#nb_prod").val()),
        nb_total_page = Math.ceil(total_element / nb_prod),
        pagination = $(".wrap_flitre_pager"),
        see_all = parseFloat($("#see_all").val()),
        thisPage = parseInt(page / nb_prod),
        initialPage = thisPage;

    page = page || 1;

    $("#page").change(function () {
        page = parseFloat($("#page").val());
        thisPage = parseInt(page / nb_prod + 1);
    });

    $("#totalElems").change(function () {
        total_element = $("#totalElems").val();
        nb_prod = Math.ceil(total_element / nb_prod);
    });

    $.ajax({
        url: path_relative_root + "ajax_reload_pagination.php",
        type: "get",
        data: {
            page: page,
            link: "paginationGoToPage()",
            nb_total_page: nb_total_page,
        },
        success: function (res) {
            /**
             * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
             */
            var productLoaded = $('#scroll_items .item_container').length;
            var total_element = document.getElementById('totalElems').value;
            var percentLoaded = (productLoaded * 100 / total_element);

            $('.nbProdDisplayed').html(Translator.translate('pagerTxt', productLoaded, total_element));

            $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

            if (productLoaded >= total_element) {
                window.setTimeout(function () {
                    $('.loader_scroll').removeClass('loading');
                }, 2000);

                $('.see_all_product').hide();
            } else {
                $('.loader_scroll').addClass('loading');
                $('.see_all_product').show();
            }

            if (productLoaded >= document.getElementById("totalElems").value) {
                $(".see_all_product").hide();
            }
        },
    });
}

var bloc_prod_xhr;

function generateNewBlocProd(
    page,
    type,
    idObj,
    nb_product,
    btn,
    fromScrollBottom,
    fromScrollTop,
    fromPagination = false
) {
    var form =
            this instanceof HTMLFormElement
                ? this
                : document.getElementById("filters_form"),
        page = page !== undefined ? page : form.getValue("page", parseFloat),
        type = type || form.getValue("type"),
        idObj = idObj || form.getValue("idObj"),
        nb_product = nb_product || form.getValue("nb_prod", parseFloat),
        startPage = parseInt($("#page_start").val()),
        output = {};

    if ($(".see_all")) {
        $(".see_all").addClass("loading");
    }

    output = triggerFilter(
        page,
        type,
        idObj,
        nb_product,
        "filters_form",
        fromPagination
    );

    if ($("#oneProduct").hasClass("actif")) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + "ajax_reload_products.php",
        type: "get",
        data: output,
        success: function (res) {
            var old_elm = document.querySelector(".list_item");
            var content, itm_length;

            if (
                btn &&
                btn.nextElementSibling &&
                (btn.nextElementSibling.classList.contains("btn_tunnel") ||
                    btn.nextElementSibling.classList.contains("loader"))
            ) {
                btn.nextElementSibling.style.display = "none";
            }

            res = res.split("><><");

            content = res[0].trim();

            itm_length = parseFloat(res[1]);

            // This block of code updates the SEO-related link elements in the HTML document.
            let canonical = res[5];
            let prev = res[6];
            let next = res[7];

            $("link[rel^='canonical']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);

            $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

            let hrefPrev = $("link[rel^='prev']");
            let hrefNext = $("link[rel^='next']");
            let commentPrev = $('#href_prev');
            let commentNext = $('#href_next');

            changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
            changeSeoElement(next, hrefNext, commentNext, 'next');

            if ($(".rayonCategSlider").length && output.object_id) {
                $(".rayonCategSlider .menuitem").removeClass("actif");
                $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                var rayonInitialSlide;

                $(".rayonCategSlider .swiper-slide").each(function (i, obj) {
                    if ($(obj).hasClass("actif")) {
                        rayonInitialSlide = i;
                    }
                });

                rayonCategSlider.slideTo(rayonInitialSlide);
            } else {
                $(".rayonCategSlider .menuitem").removeClass("actif");
            }

            if (content !== "") {
                if (!isNaN(page)) {
                    old_elm.setAttribute("data-p", page * nb_product);
                }

                if (!fromScrollBottom && !fromScrollTop) {
                    old_elm.innerHTML = "";
                }

                document.getElementById("nbr_items").innerHTML = itm_length;
                document.getElementById("totalElems").value = itm_length;

                paginate(page + 1);

                if (fromScrollTop) {
                    var firstMsg = $("#scroll_items .item_container:first");
                    var curOffset =
                        firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML("afterbegin", content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    //L'inverse pour le bas
                } else {
                    old_elm.insertAdjacentHTML("beforeend", content);
                }

                if (one_product) {
                    $("div.item_container").addClass("full");
                }

                if (parseFloat($("#filters_menu").css("right")) === 0) {
                    toggleMenu("filters", "right");
                }

                if (window.lazyload || typeof lazyload != "undefined") {
                    lazyload.init();
                }
            }
        },
        complete: function () {
            $("#totalElems").trigger("change");
            $("#bottom_reached").val("0").trigger("change");
            $("#page").trigger("change");

            if (!fromScrollBottom && !fromScrollTop) {
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
                setTimeout(function () {
                    $("html, body").animate(
                        {
                            scrollTop: $(window).scrollTop() + 10,
                        },
                        200
                    );
                }, 250);
            }

            if (fromScrollTop || fromScrollBottom) {
                $("#is_loading").val("0");

                /**
                 * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                 * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                 */

                if ($("body.category").length) {
                    var origin = $(
                        "#scroll_items .item_container:not(.push)"
                        ).find("img.watched"),
                        target = $("span.ill_img"),
                        targetHeight = origin.height();
                }
            }

            if (fromScrollBottom && !fromScrollTop) {
                $("#page_start").val(startPage + 1);

                if ($("#page_start").val() >= 1) {
                    $("#initial_page").val(page);
                }
            }

            setTimeout(function () {
                if (
                    $(btn).closest(".drawer").length &&
                    $(btn).closest(".drawer").attr("id")
                ) {
                    var menuId = $(btn)
                        .closest(".drawer")
                        .attr("id")
                        .replace("_menu", "");
                    toggleMenu(menuId);
                }
            }, 200);

            /**
             * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
             */
            var productLoaded = $('#scroll_items .item_container').length;
            var total_element = document.getElementById('totalElems').value;
            var percentLoaded = (productLoaded * 100 / total_element);

            $('.nbProdDisplayed').html(Translator.translate('pagerTxt', productLoaded, total_element));

            $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

            if (productLoaded >= total_element) {
                window.setTimeout(function () {
                    $('.loader_scroll').removeClass('loading');
                }, 2000);

                $('.see_all_product').hide();
            } else {
                $('.loader_scroll').addClass('loading');
                $('.see_all_product').show();
            }

            if (productLoaded >= document.getElementById("totalElems").value) {
                $(".see_all_product").hide();
            }

            // MAJ du nombre total de produits qu'on voit sur X page
            var newNbProd = $('#scroll_items .item_container:not(".push")').length;

            // Update Nombre total de produits qu'on voit apres avoir changer de page
            $('#page').val(newNbProd);
        },
    });
}

function changeFiltre(form, reset) {
    var value = getInputVal.bind(form);
    var type = value("type_tri"),
        idObj;
    var btn = this,
        idObjTmp;

    // If form must be reset
    if (reset) {
        if (filterformColors && filterformSize) {
            document.getElementById("wrapper_couleur_filtre").innerHTML =
                filterformColors;
            document.getElementById("wrapper_taille_filtre").innerHTML =
                filterformSize;
        }

        resetExtraFilters();
        form.reset();
        $("#filters_menu input[checked]")
            .not("#wrapper_sscat_filtre input[checked]")
            .removeAttr("checked"); //force suppression de tous les filtres checked (sauf ss-cat), ce qui arrive lorsqu'on revient d'une FP.
    }
    $("legend.open", form).each(function () {
        toggleFiltersMenu.call(this);
    });

    // Check obj type
    if (form.sscat) {
        // Attention ce n'est pas des sscat mais des bien objets (views_mobile_new/wrapper_filters.php)
        idObjTmp = value("sscat");

        if (idObjTmp && idObjTmp.length > 0) {
            type = "obj";
            $("#object_id").val(idObjTmp);
        } else {
            type = "subcat";
        }
    }

    $("#type_tri").val(type);

    idObj = getCurrentIdRayon();

    // Display loader
    if (
        btn.nextElementSibling &&
        btn.nextElementSibling.classList.contains("button")
    ) {
        btn.nextElementSibling.style.display = "block";
    }

    // Get new elements
    if (from_type === "product") {
        generateNewBlocProd.call(form, 0, type, idObj, value("nb_prod"), btn);
    } else {
        generateNewBlocSearch.call(form, value("nb_prod"), 0, btn);
    }

    return false;
}

// product listing swiper
// on scroll, inits the swipers when they are in view and destroys them when they are not, does not re-init if already inited
function initProductSliders() {
    // grab all IDs starting with prodSlider- and check if they are in view using the isInViewport function
    var sliders = document.querySelectorAll('[id^="prodSlider-"]');
    for (var i = 0; i < sliders.length; i++) {
        if (isInViewport(sliders[i])) {
            if (!sliders[i].classList.contains('swiper-container-initialized')) {
                new Swiper('#' + sliders[i].id, {
                    slidesPerView: 1,
                    loop: true,
                    preloadImages: false,
                    lazy: true,
                    pagination: {
                        el: '#' + sliders[i].id + ' .itemSliderPagination',
                        type: 'bullets',
                    }
                });
                sliders[i].classList.add('swiper-container-initialized');
            }
        } else {
            if (sliders[i].classList.contains('swiper-container-initialized')) {
                sliders[i].swiper.destroy();
                sliders[i].classList.remove('swiper-container-initialized');
            }
        }
    }
}

document.addEventListener('DOMContentLoaded', function () {
    initProductSliders();
});

document.addEventListener('scroll', function () {
    initProductSliders();
});

// make an updateProductSliders function that can be called when the #twoProducts and #oneProduct buttons are clicked
function updateProductSliders() {
    setTimeout(() => {
        let sliders = document.querySelectorAll('[id^="prodSlider-"]');
        for (let i = 0; i < sliders.length; i++) {
            if (sliders[i].classList.contains('swiper-container-initialized')) {
                sliders[i].swiper.update();
                sliders[i].swiper.slideTo(1);
            }
        }
    }, 200);
}

document.addEventListener('DOMContentLoaded', function () {
    // on click of the #twoProducts and #oneProduct buttons, call the updateProductSliders function
    document.getElementById('twoProducts').addEventListener('click', updateProductSliders);
    document.getElementById('oneProduct').addEventListener('click', updateProductSliders);
    // listen on the body, if there's a color change (click on .preview_color), call the updateProductSliders function
    document.body.addEventListener('click', function (e) {
        if (e.target.classList.contains('preview_color')) {
            updateProductSliders();
        }
    });
});

// Function to be triggered when class is added to body
function handleClassAddedToBody() {
    if ($('body').hasClass('search')) {
        if (!$('.loader_scroll button.see_all_product ').length > 0) {
            $('.loader_scroll').append('<button class="see_all_product button primary"></button><div class="button primary loader"></div>');
            $('.loader_scroll').wrap('<div class="pagerNav end_pagi" style="opacity: 0;">');
        }

        $('.loader_scroll .see_all_product').on('click', function () {
            var totalElems = $('#totalElems').val(),
                page = parseFloat($('#page').val()), // Nombre total de produits qu'on voit (Y named page no idea 😕)
                nbProd = parseFloat($('#nb_prod').val()), // Nombre de produits par default
                thisPage = parseInt(page / nbProd), // nb total de prod qu'on voit / nb de prod par default
                initialPage = thisPage;

            var $seeAllProduct = $(this);
            var $loaderScroll = $('.loader_scroll');

            $seeAllProduct.addClass('loading');

            if ($('.dynasearchwrapper').length === 0 && $('#scroll_items .item_container:not(".push")').length < totalElems) {
                thisPage = thisPage + 1;

                var paginationPromise = new $.Deferred(function (deferred) {
                    paginationGoToPage(thisPage, true, false);
                    setTimeout(function () {
                        deferred.resolve();
                    }, 1000);
                });

                paginationPromise.done(function () {
                    $seeAllProduct.removeClass('loading');
                    $loaderScroll.css('opacity', 1);
                });
            }
        });

        // MAJ du nombre total de produits qu'on voit sur X page
        var newNbProd = $('#scroll_items .item_container:not(".push")').length;

        // Update Nombre total de produits qu'on voit apres avoir changer de page
        $('#page').val(newNbProd);
    }
}

// Create a MutationObserver instance
const observer = new MutationObserver(mutationsList => {
    for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            // Check if the class was added to body
            if (document.body.classList.contains('search')) {
                // Call the function when the class is added to body
                handleClassAddedToBody();
                observer.disconnect();
            }
        }
    }
});

// Start observing changes in body attributes
observer.observe(document.body, {attributes: true});

// Check request validity of the order return
function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });
});
